import React, { useState } from "react"
import Style from "./image-card.style"
import WithCard from "./with-card"
import WithOverlay from "./with-overlay"
import workInfoHelper from "./work-info/WorkInfoHelper"
import { typographyStyles } from "../styleguide/typography-styles"

interface IImageCardProps {
  title: string
  subtitle: string
  backgroundColor: string
  imageURL: string
  imageAlt: string
  imageName: string
  itemId: string
}

interface ICardText {
  title: string
  subtitle: string
}

const OverlayText = ({ title, subtitle }: ICardText) => {
  return (
    <Style.OverlayText {...typographyStyles.CardOverlay}>
      {title}
      <br />
      <br />
      <br />
      {subtitle}
    </Style.OverlayText>
  )
}

const ImageCard: React.FC<IImageCardProps> = props => {
  const [isHovering, setIsHovering] = useState(false)

  const updateHover = (isHovering: boolean) => {
    setIsHovering(isHovering)
  }

  const workInfo = workInfoHelper(props.itemId)
  if (!workInfo) {
    return <></>
  }
  // var imageName: ImageName | null
  // switch (props.imageName) {
  //   case "contact-if-found-icon":
  //     console.log("got contact icon")
  //     imageName = ImageName.ICON_CONTACT_IF_FOUND
  //     break
  //   case "image-palette-icon":
  //     console.log("got image palette icon")
  //     imageName = ImageName.ICON_IMAGE_PALETTE
  //     break
  //   default:
  //     imageName = null
  //     break
  // }
  const image = (
    <Style.CenteredContainer>
      {/* <Style.FillImage src={props.imageURL} alt={props.imageAlt} /> */}
      <Style.FillImage imageName={workInfo.imageName}></Style.FillImage>
    </Style.CenteredContainer>
  )

  var content: JSX.Element
  if (isHovering) {
    content = (
      <WithOverlay>
        <OverlayText title={props.title} subtitle={workInfo.type}></OverlayText>
        {image}
      </WithOverlay>
    )
  } else {
    content = image
  }

  return (
    <WithCard
      backgroundColor={workInfo.backgroundColor}
      updateHover={updateHover}
    >
      {content}
    </WithCard>
  )
}

export default ImageCard
