import { ImageName } from "./WorkImage"
import { WorkType, IWorkInfo } from "./work-info"

const info = (): IWorkInfo => {
  const info: IWorkInfo = {
    backgroundColor: "#9ae8b1",
    imageName: ImageName.ICON_CONTACT_IF_FOUND,
    type: WorkType.IOS_APP,
    appStoreLink:
      "https://apps.apple.com/us/app/contact-if-found/id1127151351?ls=1",
    sections: [
      {
        subheading:
          "An app that provides a home screen widget displaying contact information in the event someone finds your phone. This was actually my friends idea from when he found a stranger's phone but had no way of getting in contact with them since the phone was locked.",
        images: [
          {
            imageName: ImageName.SCREEN_CONTACT_IF_FOUND,
            width: "40%",
            maxWidth: "400px",
          },
        ],
      },
      {
        subheading:
          "The hope is that if someone finds your phone they can go to the widgets and see who to contact to get in touch with you.",
        images: [],
      },
    ],
  }
  return info
}

export default info
