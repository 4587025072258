import React from "react"
import Style from "./image-card.style"

interface HoverFunc {
  (isHovering: boolean): void
}

interface IWithCard {
  backgroundColor: string
  children: JSX.Element
  updateHover: HoverFunc
}

const withCard = (props: IWithCard) => {
  return (
    <Style.FixedCard
      backgroundColor={props.backgroundColor}
      onMouseEnter={() => props.updateHover(true)}
      onMouseLeave={() => props.updateHover(false)}
    >
      {props.children}
    </Style.FixedCard>
  )
}

export default withCard
