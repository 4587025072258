import { ImageName } from "./WorkImage"
import { WorkType, IWorkInfo, IWorkSection } from "./work-info"

import talkspaceInfo from "./talkspace-info"
import resoluteInfo from "./resolute-info"
import imagePaletteInfo from "./image-palette-info"
import contactIfFoundInfo from "./contact-if-found-info"
import planeGameInfo from "./plane-game-info"
import myBlogInfo from "./my-blog-info"
import thisWebsiteInfo from "./this-website-info"

export enum WorkItem {
  IMAGE_PALETTE = "image-palette",
  CONTACT_IF_FOUND = "contact-if-found",
  PLANE_GAME = "plane-game-arcade",
  TALKSPACE = "talkspace",
  RESOLUTE = "resolute",
  MY_BLOG = "my-blog",
  THIS_WEBSITE = "this-website",
}

export const workInfo = (item: WorkItem): IWorkInfo | null => {
  var info: IWorkInfo
  switch (item) {
    case WorkItem.IMAGE_PALETTE:
      info = imagePaletteInfo()
      break
    case WorkItem.CONTACT_IF_FOUND:
      info = contactIfFoundInfo()
      break
    case WorkItem.PLANE_GAME:
      info = planeGameInfo()
      break
    case WorkItem.TALKSPACE:
      info = talkspaceInfo()
      break
    case WorkItem.RESOLUTE:
      info = resoluteInfo()
      break
    case WorkItem.MY_BLOG:
      info = myBlogInfo()
      break
    case WorkItem.THIS_WEBSITE:
      info = thisWebsiteInfo()
      break
  }
  return info
}

const workItem = (workId: string): WorkItem | null => {
  var item: WorkItem
  switch (workId) {
    case "image-palette":
      item = WorkItem.IMAGE_PALETTE
      break
    case "contact-if-found":
      item = WorkItem.CONTACT_IF_FOUND
      break
    case "plane-game-arcade":
      item = WorkItem.PLANE_GAME
      break
    case "talkspace":
      item = WorkItem.TALKSPACE
      break
    case "resolute":
      item = WorkItem.RESOLUTE
      break
    case "my-blog":
      item = WorkItem.MY_BLOG
      break
    case "this-website":
      item = WorkItem.THIS_WEBSITE
      break
    default:
      return null
  }
  return item
}

const workInfoHelper = (workId: string): IWorkInfo | null => {
  const item = workItem(workId)
  if (item) {
    return workInfo(item)
  } else {
    return null
  }
}

export const bgColor = (workId: string): string => {
  const info = workInfoHelper(workId)
  return info?.backgroundColor ?? "white"
}

export default workInfoHelper
