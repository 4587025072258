import React from "react"
import IconImagePalette from "../images/IconImagePalette"
import IconContactIfFound from "../images/IconContactIfFound"
import IconPlaneGame from "../images/IconPlaneGame"
import IconTalkspace from "../images/IconTalkspace"
import IconResolute from "../images/IconResolute"
import IconMyBlog from "../images/IconMyBlog"
import IconThisWebsite from "../images/IconThisWebsite"
import ScreenshotImagePalette from "../images/ScreenshotImagePalette"
import ScreenshotContactIfFound from "../images/ScreenshotContactIfFound"
import ScreenshotPlaneGame from "../images/ScreenshotPlaneGame"

export enum ImageName {
  ICON_IMAGE_PALETTE = "image-palette-icon",
  ICON_CONTACT_IF_FOUND = "contact-if-found-icon",
  ICON_PLANE_GAME = "plane-game-icon",
  ICON_TALKSPACE = "talkspace-icon",
  ICON_RESOLUTE = "resolute-icon",
  ICON_MY_BLOG = "my-blog-icon",
  ICON_THIS_WEBSITE = "this-website-icon",
  SCREEN_IMAGE_PALETTE = "image-palette-screenshot",
  SCREEN_CONTACT_IF_FOUND = "contact-if-found-screenshot",
  SCREEN_PLANE_GAME = "plane-game-screenshot",
}

interface WorkImageProps {
  imageName: ImageName
}

const workImage = ({ imageName, ...props }: WorkImageProps) => {
  var content: JSX.Element
  switch (imageName) {
    case ImageName.ICON_IMAGE_PALETTE:
      content = <IconImagePalette {...props} />
      break
    case ImageName.ICON_CONTACT_IF_FOUND:
      content = <IconContactIfFound {...props} />
      break
    case ImageName.ICON_PLANE_GAME:
      content = <IconPlaneGame {...props} />
      break
    case ImageName.SCREEN_IMAGE_PALETTE:
      content = <ScreenshotImagePalette {...props} />
      break
    case ImageName.SCREEN_CONTACT_IF_FOUND:
      content = <ScreenshotContactIfFound {...props} />
      break
    case ImageName.SCREEN_PLANE_GAME:
      content = <ScreenshotPlaneGame {...props} />
      break
    case ImageName.ICON_TALKSPACE:
      content = <IconTalkspace {...props} />
      break
    case ImageName.ICON_RESOLUTE:
      content = <IconResolute {...props} />
      break
    case ImageName.ICON_MY_BLOG:
      content = <IconMyBlog {...props} />
      break
    case ImageName.ICON_THIS_WEBSITE:
      content = <IconThisWebsite {...props} />
      break
  }
  return content
}

export default workImage
