import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

/** @typedef {{
 * to: string,
 * duration?: number,
 * children: JSX.Element | JSX.Element[]
 * }} BaseProps
 * */

/** @typedef {{
 * direction?: "left" | "right" | "up" | "down"
 * bg: string
 * }} CoverProps
 * */

/**
 * @param props {BaseProps & CoverProps}
 * @return {JSX.Element}
 */
export const CoverLink = ({ duration, direction, children, ...props }) => {
  const dur = duration ?? 1.25
  const dir = direction ?? "up"
  return (
    <AniLink cover direction={dir} duration={dur} {...props}>
      {children}
    </AniLink>
  )
}

/** @typedef {{
 * to: string,
 * duration?: number,
 * children: React.ReactNode | JSX.Element | JSX.Element[]
 * }} FadeProps
 * */

/**
 * @param props {FadeProps}
 * @return {JSX.Element}
 */
export const FadeLink = ({ duration, children, to, ...props }) => {
  const dur = duration ?? 0.5
  return (
    <AniLink fade to={to} duration={5}>
      {children}
    </AniLink>
  )
}
// export const FadeLink = ({ duration, children, ...props }) => {
//   const dur = duration ?? 0.5
//   return (
//     <AniLink fade duration={dur} {...props}>
//       {children}
//     </AniLink>
//   )
// }
