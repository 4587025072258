import { ImageName } from "./WorkImage"
import { WorkType, IWorkInfo } from "./work-info"

const info = (): IWorkInfo => {
  const info: IWorkInfo = {
    backgroundColor: "#4D4D4D",
    imageName: ImageName.ICON_THIS_WEBSITE,
    type: WorkType.REACT,
    sections: [
      {
        subheading:
          "I built this website using React and Gatsby. I really like React because it's the first web technology that makes sense for me. React creates single page applications which is very similar to the idea of iOS apps, in that you create reusable components. This has made web development so much more enjoyable for me. I stayed away from working on websites because, to me, HTML was tedious and error prone when making changes. Using React has made building websites feel much more like programming and less like writing markdown. I chose Gatsby becuause it makes creating static website pages trivial, and because it has accessibility built in.",
        images: [],
      },
    ],
  }
  return info
}

export default info
