import { ImageName } from "./WorkImage"
import { WorkType, IWorkInfo } from "./work-info"

const info = (): IWorkInfo => {
  const info: IWorkInfo = {
    backgroundColor: "#6AB8D7",
    imageName: ImageName.ICON_IMAGE_PALETTE,
    type: WorkType.IOS_APP,
    sections: [
      {
        subheading:
          "An iOS app, currently waiting App Store approval, that helps sort photos by color. The idea for this app came about as way to structure an instagram feed by color. Many popular instagram accounts will post photos in a way that when the full grid is viewed the colors of the photos flow together. My idea was to create an app that I can dump X amount of photos into, and have them sorted so I wouldn't have to manaually go through each photo to see which ones looked similar.",
        images: [
          {
            imageName: ImageName.SCREEN_IMAGE_PALETTE,
            width: "40%",
            maxWidth: "400px",
          },
        ],
      },
      {
        subheading:
          "The screenshot above demonstrates the app sorting the photos from a darker average color at the top to a lighter average color at the bottom. This app was challenging because there isn't a perfect way to sort colors, since they are made up of 3 values: red, green, and blue. The end result isn't perfect but it's a pretty good way to group similar-ish photos with each other.",
        images: [],
      },
    ],
  }
  return info
}

export default info
