import { ImageName } from "./WorkImage"
import { WorkType, IWorkInfo } from "./work-info"

const info = (): IWorkInfo => {
  const info: IWorkInfo = {
    backgroundColor: "#1C1C64",
    imageName: ImageName.ICON_RESOLUTE,
    type: WorkType.IOS_DEVELOPER,
    sections: [
      {
        subheading:
          "Resolute Digital is a digital agency. While working at Resolute I was the lone iOS developer. One of the cool things about working at an agency was constantly working on new projects. One of my favorite apps I created at Resolute was called 'In Between Us'. This app would find the midpoint between 2 or more locations and then display it on a Google map. Users could then filter the midpoint for different types of meeting places like bars or restaurants. This was just one of many apps I worked on, and since there were new clients coming in that meant I got to work on a variety of different apps. I also had a lot of freedom in how I could approach structuring these apps, which let me do a lot of experimentation.",
        images: [],
      },
      {
        subheading:
          "Since many of the apps were created from the ground up I got to try out different strategies for the app architecture. The default iOS architecture is Model View Controller (MVC), this works well when done right but can very easily be abused. A common dig at MVC is calling it the Massive View Controller since it can lead to dumping alot of code inside of view controllers. Some of the apps I created followed MVC, and others I tried out other ways like MVVM and VIPER. I ended up doing a few apps the 'Clean Swift' way which leveraged the VIP cycle.",
        images: [],
      },
      {
        subheading:
          "Being able to start from scratch every few months was a really cool part of working at Resolute. This allowed me to constantly learn new things and try out some of the ideas I had without needing to refactor a whole existing app. I think working at an agency has made me enjoy faster pace work and working on a broad range of the iOS frameworks.",
        images: [],
      },
    ],
  }
  return info
}

export default info
