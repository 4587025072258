import { ImageName } from "./WorkImage"

export enum WorkType {
  IOS_APP = "iOS App",
  IOS_DEVELOPER = "iOS Developer",
  REACT = "React Web App",
  WORDPRESS = "Wordpress Blog",
}

export interface IWorkSection {
  heading?: string
  subheading?: string
  images: {
    imageName: ImageName
    width: string
    maxWidth?: string
  }[]
}

export interface IWorkInfo {
  backgroundColor: string
  imageName: ImageName
  type: WorkType
  appStoreLink?: string
  githubLink?: string
  sections: IWorkSection[]
}
