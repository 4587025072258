import { ImageName } from "./WorkImage"
import { WorkType, IWorkInfo } from "./work-info"

const info = (): IWorkInfo => {
  const info: IWorkInfo = {
    backgroundColor: "#22A7F0",
    imageName: ImageName.ICON_PLANE_GAME,
    type: WorkType.IOS_APP,
    sections: [
      {
        subheading:
          "An iOS game featuring WWII planes. The goal is to avoid incoming planes as long as you can before crashing. Includes a high score, unlockable planes and achievements.",
        images: [
          {
            imageName: ImageName.SCREEN_PLANE_GAME,
            width: "40%",
            maxWidth: "400px",
          },
        ],
      },
      {
        subheading:
          "This was the first app I ever created back in 2010. Written in objective-c the entire game runs in a massive view controller using a timer to simulate gravity and detect collisions. I've come a long way since making this app but it still makes me smile everytime I play it (hopefully I'll give it a proper update soon).",
        images: [],
      },
    ],
  }
  return info
}

export default info
