import { ImageName } from "./WorkImage"
import { WorkType, IWorkInfo } from "./work-info"

const info = (): IWorkInfo => {
  const info: IWorkInfo = {
    backgroundColor: "#0FC1A7",
    imageName: ImageName.ICON_TALKSPACE,
    type: WorkType.IOS_DEVELOPER,
    appStoreLink:
      "https://apps.apple.com/us/app/talkspace-online-therapy/id661829386",
    sections: [
      {
        subheading:
          "Talkspace is an online therapy startup. I worked for about a year and a half as an iOS engineer at Talkspace. While working there I pushed new features on a 2 week sprint cycle. I pretty much worked on all parts of the app like frontend ui, Core Data, push notifications, analytics, the networking layer, unit testing, fastlane, certificates, provisioning profiles and modularization.",
        images: [],
      },
      {
        subheading:
          "One of the biggest challenges I faced at Talkspace was dealing with legacy code and technical debt. However, this helped me learn how to budget my time better in that I had to decide when it was worth refactoring something or not. I tend to be a perfectionist to a fault, so my experience working in a faster pace startup helped me see that not everything needs be perfect to work. This is not to say the code was horrible, but focusing too much on a little aspect of code can be detrimental, it helps to step back and look at the bigger picture.",
        images: [],
      },
      {
        subheading:
          "While at Talkspace I also worked in a team atmosphere. I enjoyed learning from other people at the company within the mobile team and also from the fullstack engineers as well. I also learned how to communicate with remote team members that were not always in the same timezone. Overall it was a rewarding experience working with many team members with differing ideas.",
        images: [],
      },
    ],
  }
  return info
}

export default info
