import { ImageName } from "./WorkImage"
import { WorkType, IWorkInfo } from "./work-info"

const info = (): IWorkInfo => {
  const info: IWorkInfo = {
    backgroundColor: "#FABE58",
    imageName: ImageName.ICON_MY_BLOG,
    type: WorkType.WORDPRESS,
    sections: [
      {
        subheading:
          "I have a blog called Protein and Programming that I use to post about interesting programming concepts and physical health. Programming and being physically active are two of my favorite things so I enjoy writing about them in my free time. I've found that writing about programming helps me learn it better. I have to think about how to explain it which helps me understand it deeper.",
        images: [],
      },
      {
        subheading:
          "To power this blog I have a wordpress installation running on a linux server. I created a custom wordpress theme which styles all the elements of the blog. The one downside is that customizing the theme is a bit of a hassle because of how wordpress works. I think I may port my blog over to React eventually which will give me more control over how the blog works.",
        images: [],
      },
    ],
  }
  return info
}

export default info
