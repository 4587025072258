import React from "react"
import styled from "styled-components"

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
`

interface IWithOverlay {
  children?: JSX.Element[]
}

const withOverlay = ({ children }: IWithOverlay) => {
  return (
    <>
      {children}
      <Overlay></Overlay>
    </>
  )
}

export default withOverlay
