import styled from "styled-components"
import WorkImage from "./work-info/WorkImage"

const OverlayText = styled.p`
  padding: 0px;
  margin: 0px;
  width: 85%;
  text-align: center;
  color: white;
  z-index: 150;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

// const FillImage = styled.img`
//   height: 100%;
//   width: 100%;
//   object-fit: contain;
// `

// const Label = styled.label<SomeProps>`
//   /* color: ${colors.primary}; */
//   /* color: red; */
//   color: ${p => p.color ?? colors.primary};
//   font-family: ${fontFamilies.heading};
// `

const FillImage = styled(WorkImage)`
  height: 100%;
  width: 100%;
  object-fit: contain;
`

const CenteredContainer = styled.div`
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  margin-left: -100px;
  top: 50%;
  margin-top: -100px;
`

interface IFixedCard {
  readonly backgroundColor: string
}

const FixedCard = styled.div<IFixedCard>`
  font-weight: bold;
  /* font-size: 40px; */
  height: 400px;
  width: 100%;
  background-color: ${props => props.backgroundColor};
  position: relative;
  &:hover ${FillImage} {
    opacity: 0.3;
  }
`

const Style = {
  CenteredContainer,
  FillImage,
  FixedCard,
  OverlayText,
}

export default Style
