import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Icon = (props: any) => {
  const data = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "contact-if-found-icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const alt = "App icon for Contact If Found"

  return (
    <Img
      fluid={data.icon.childImageSharp.fluid}
      alt={alt}
      style={{ borderRadius: "18%" }}
      {...props}
    />
  )
}

export default Icon
