import React from "react"
import { Grid, Row, Col } from "react-flexbox-grid"
import { Link, graphql } from "gatsby"
import { Location } from "@reach/router"
import { addDecorator, configure } from "@storybook/react"
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageCard from "../components/image-card"
import "./index.css"
import { ImageName } from "../components/work-info/WorkImage"
import { bgColor } from "../components/work-info/WorkInfoHelper"

import { CoverLink, FadeLink } from "../components/AnimatedLink"
import FadeInLayout from "../components/LayoutTransitions/FadeInLayout"
import { Typography } from "../styleguide/typography"
const { SmallLead } = Typography

interface IWork {
  id: string
  name: string
  type: string
}

interface Props {
  data: {
    allMarkdownRemark: any
    site: {
      siteMetadata: {
        title: string
      }
    }
    allProjectsJson: {
      edges: [
        {
          node: IWork
        }
      ]
    }
  }
}

const createWork = (item: IWork, index: number) => {
  const path = `/${item.id}`
  var smSize: number
  if (index != 0 && (index + 1) % 3 == 0) {
    smSize = 12
  } else {
    smSize = 6
  }
  const bg = bgColor(item.id)
  const work = (
    <Col xs={12} sm={smSize} key={item.id}>
      <CoverLink to={path} bg={bg}>
        <ImageCard
          title={item.name}
          subtitle="App subtitle 1"
          backgroundColor="blue"
          imageURL="https://cdn.vox-cdn.com/thumbor/p_kqOx1QlqKt7ueruH6khWI4P3Y=/0x0:1280x800/1200x0/filters:focal(0x0:1280x800):no_upscale()/cdn.vox-cdn.com/uploads/chorus_asset/file/13293235/apple15.png"
          imageAlt="Image alt"
          imageName={item.id}
          itemId={item.id}
        />
      </CoverLink>
    </Col>
  )
  return work
}

const IndexPage = ({ data }: Props) => {
  const siteTitle = data.site.siteMetadata.title

  var profWorks: IWork[] = []
  var myWorks: IWork[] = []

  data.allProjectsJson.edges.forEach(edge => {
    const node = edge.node
    if (node.type === "professional") {
      profWorks.push(node)
    } else {
      myWorks.push(node)
    }
  })

  const professionalWorks = profWorks.map((item, i) => {
    return createWork(item, i)
  })

  const personalWorks = myWorks.map((item, i) => {
    return createWork(item, i)
  })

  // data.allProjectsJson.edges.forEach((edge, i) => {
  //   const node = edge.node
  //   console.log(`node = ${JSON.stringify(edge)}`)
  //   const path = `/${node.id}`
  //   var smSize: number
  //   if (i != 0 && (i + 1) % 3 == 0) {
  //     smSize = 12
  //   } else {
  //     smSize = 6
  //   }
  //   const bg = bgColor(node.id)
  //   const work = (
  //     <Col xs={12} sm={smSize} key={node.id}>
  //       <CoverLink to={path} bg={bg}>
  //         <ImageCard
  //           title={node.name}
  //           subtitle="App subtitle 1"
  //           backgroundColor="blue"
  //           imageURL="https://cdn.vox-cdn.com/thumbor/p_kqOx1QlqKt7ueruH6khWI4P3Y=/0x0:1280x800/1200x0/filters:focal(0x0:1280x800):no_upscale()/cdn.vox-cdn.com/uploads/chorus_asset/file/13293235/apple15.png"
  //           imageAlt="Image alt"
  //           imageName={node.id}
  //           itemId={node.id}
  //         />
  //       </CoverLink>
  //       {/* <Link to={path}>
  //         <ImageCard
  //           title={node.name}
  //           subtitle="App subtitle 1"
  //           backgroundColor="blue"
  //           imageURL="https://cdn.vox-cdn.com/thumbor/p_kqOx1QlqKt7ueruH6khWI4P3Y=/0x0:1280x800/1200x0/filters:focal(0x0:1280x800):no_upscale()/cdn.vox-cdn.com/uploads/chorus_asset/file/13293235/apple15.png"
  //           imageAlt="Image alt"
  //           imageName={node.id}
  //           itemId={node.id}
  //         />
  //       </Link> */}
  //     </Col>
  //   )
  //   if (node.type === "professional") {
  //     professionalWorks.push(work)
  //   } else {
  //     personalWorks.push(work)
  //   }
  // })

  // const works = data.allProjectsJson.edges.map((edge, i) => {
  //   const node = edge.node
  //   console.log(`node = ${JSON.stringify(edge)}`)
  //   const path = `/${node.id}`
  //   var smSize: number
  //   if (i != 0 && (i + 1) % 3 == 0) {
  //     smSize = 12
  //   } else {
  //     smSize = 6
  //   }
  //   const bg = bgColor(node.id)
  //   return (
  //     <Col xs={12} sm={smSize} key={node.id}>
  //       <CoverLink to={path} bg={bg}>
  //         <ImageCard
  //           title={node.name}
  //           subtitle="App subtitle 1"
  //           backgroundColor="blue"
  //           imageURL="https://cdn.vox-cdn.com/thumbor/p_kqOx1QlqKt7ueruH6khWI4P3Y=/0x0:1280x800/1200x0/filters:focal(0x0:1280x800):no_upscale()/cdn.vox-cdn.com/uploads/chorus_asset/file/13293235/apple15.png"
  //           imageAlt="Image alt"
  //           imageName={node.id}
  //           itemId={node.id}
  //         />
  //       </CoverLink>
  //       {/* <Link to={path}>
  //         <ImageCard
  //           title={node.name}
  //           subtitle="App subtitle 1"
  //           backgroundColor="blue"
  //           imageURL="https://cdn.vox-cdn.com/thumbor/p_kqOx1QlqKt7ueruH6khWI4P3Y=/0x0:1280x800/1200x0/filters:focal(0x0:1280x800):no_upscale()/cdn.vox-cdn.com/uploads/chorus_asset/file/13293235/apple15.png"
  //           imageAlt="Image alt"
  //           imageName={node.id}
  //           itemId={node.id}
  //         />
  //       </Link> */}
  //     </Col>
  //   )
  // })

  // <p>Professional Work</p>
  // <p>Personal Work</p>
  // return (
  //   <Layout location={window.location}>
  //     <SEO title="Home" />
  //     <FadeInLayout>
  //       <Grid fluid>
  //         <Row className="no-gutters">
  //           {professionalWorks}
  //           {personalWorks}
  //         </Row>
  //       </Grid>
  //     </FadeInLayout>
  //   </Layout>
  // )

  return (
    <Location>
      {({ location }) => {
        return (
          <Layout location={location}>
            <SEO
              title="Home"
              description="Home page for AJ Bartocci's portfolio"
            />
            <FadeInLayout>
              <SmallLead>Professional Work</SmallLead>
              <Grid fluid>
                <Row className="no-gutters">{professionalWorks}</Row>
              </Grid>
              <SmallLead>Personal Work</SmallLead>
              <Grid fluid>
                <Row className="no-gutters">{personalWorks}</Row>
              </Grid>
            </FadeInLayout>
          </Layout>
        )
      }}
    </Location>
  )

  // return (
  //   <Layout location={window.location}>
  //     <SEO title="Home" />
  //     <Grid fluid>
  //       <Row className="no-gutters">
  //         <Col xs={12} sm={6}>
  //           <Link to="/image-palette">
  //             <ImageCard
  //               title="App Name 1"
  //               subtitle="App subtitle 1"
  //               backgroundColor="blue"
  //               imageURL="https://cdn.vox-cdn.com/thumbor/p_kqOx1QlqKt7ueruH6khWI4P3Y=/0x0:1280x800/1200x0/filters:focal(0x0:1280x800):no_upscale()/cdn.vox-cdn.com/uploads/chorus_asset/file/13293235/apple15.png"
  //               imageAlt="Image alt"
  //               imageName="icon-image-palette"
  //             />
  //           </Link>
  //         </Col>
  //         <Col xs={12} sm={6}>
  //           <Link to="contact-if-found">
  //             <ImageCard
  //               title="App Name 2"
  //               subtitle="App subtitle 2"
  //               backgroundColor="red"
  //               imageURL="https://cdn.vox-cdn.com/thumbor/p_kqOx1QlqKt7ueruH6khWI4P3Y=/0x0:1280x800/1200x0/filters:focal(0x0:1280x800):no_upscale()/cdn.vox-cdn.com/uploads/chorus_asset/file/13293235/apple15.png"
  //               imageAlt="Image alt"
  //               imageName={ImageName.ICON_IMAGE_PALETTE}
  //             />
  //           </Link>
  //         </Col>
  //         <Col xs={12}>
  //           <ImageCard
  //             title="App Name 3"
  //             subtitle="App subtitle 3"
  //             backgroundColor="green"
  //             imageURL="https://cdn.vox-cdn.com/thumbor/p_kqOx1QlqKt7ueruH6khWI4P3Y=/0x0:1280x800/1200x0/filters:focal(0x0:1280x800):no_upscale()/cdn.vox-cdn.com/uploads/chorus_asset/file/13293235/apple15.png"
  //             imageAlt="Image alt"
  //             imageName={ImageName.ICON_IMAGE_PALETTE}
  //           />
  //         </Col>
  //       </Row>
  //     </Grid>
  //   </Layout>
  // )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allProjectsJson {
      edges {
        node {
          id
          name
          type
        }
      }
    }
  }
`
